<template>
  <v-app-bar app class="elevation-1" color="primary">
    <v-app-bar-nav-icon
      @click.stop="expand"
      style="color: #ffffff"
    ></v-app-bar-nav-icon>
    <h4 class="font font-md font-weight-bold text-uppercase white--text">
      Kamccu Admin Portal
    </h4>
    <v-spacer></v-spacer>
    <v-menu
      offset-y
      content-class="dropdown-menu"
      min-width="200"
      transition="slide-y-transition"
    >
      <template v-slot:activator="{ on }">
        <a v-on="on" class="ml-7 mt-4 mb-3"
          ><v-icon size="30" style="color: #ffffff" left
            >mdi-account-circle</v-icon
          >
        </a>
      </template>
      <v-card tile>
        <v-list dense>
          <v-list-item
            nudge-right="50px"
            v-for="(option, idx) in options"
            :key="idx"
            @click="state(option.slug)"
          >
            <v-list-item-title class="ma-n1 font font-md">{{
              option.title
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>

<script>
import * as axios from 'axios';

export default {
  name: 'Appbar',
  data: () => ({
    drawer: false,
    //logoutDialog: false,
    passwordDialog: false,
    show: false,
    show1: false,
    show2: false,
    progress: false,
    password: '',
    newPassword: '',
    confirmPassword: '',
    errorMessage: '',
    passwordMessage: '',
    confirmPasswordMessage: '',
    newPasswordMessage: '',
    formHasErrors: false,
    rules: {
      requiredPassword: value => !!value || 'Password is required.',
      min: value => value.length >= 6 || 'At least 6 characters',
    },
    options: [
      // { title: 'Profile', slug: 'profile' },
      { title: 'Logout', slug: 'logout' },
    ],
  }),

  computed: {
    form() {
      return {
        password: this.password,
        newPassword: this.newPassword,
      };
    },

    passwordConfirmationRule() {
      return () =>
        this.confirmPassword === this.newPassword || 'Password must match';
    },
  },

  methods: {
    changePassword() {
      this.progress = true;
      if (this.confirmPassword === this.newPassword) {
        setTimeout(() => {
          axios({
            method: 'post',
            headers: {
              authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            url: `${process.env.VUE_APP_BASE}/entry/change_password`,
            data: {
              current_password: this.password,
              new_password: this.newPassword,
            },
          })
            .then(response => {
              if (response.status === 200) {
                this.progress = false;
                this.passwordDialog = false;
                this.logout();
              } else {
                //
              }
            })
            .catch(err => {
              // console.log(err);
              this.passwordMessage =
                err.response != null ? err.response.data.message : err.message;
              if (err.response.status === 401) {
                this.$router.replace({ path: '/login' });
              }
            });
        }, 5000);
      } else {
        // console.log("passwords don't match");
      }
    },
    state(value) {
      switch (value) {
        case 'logout':
          this.logout();
          //this.logoutDialog = true;

          break;
        default:
          break;
      }
    },

    alert() {
      this.$swal('Are you sure you want to log out?', {
        buttons: {
          cancel: true,
          confirm: 'Yes',
        },
      }).then(value => {
        if (value) {
          this.logout();
        }
      });
    },
    expand() {
      this.drawer = !this.drawer;
      this.$emit('drawer', this.drawer);
    },

    logout() {
      this.$store.dispatch('auth/logOut');
    },
  },
};
</script>

<style scoped>
.button {
  background-color: #ffffff; /* Green */
  border: none;
  color: white;
  padding: 6px 10px;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-right: 20px;
  cursor: pointer;
}

.button1 {
  background-color: #4caf50; /* Green */
  border: none;
  color: white;
  padding: 6px 10px;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-right: 45px;
  cursor: pointer;
}
</style>
